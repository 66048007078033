<template>
  <b-container
    v-if="loaded"
    fluid
  >
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="6">
        <h1>Cancel Workout</h1>
        <hr>
        <b-alert
          v-if="!event.canCancelEvent"
          show
          variant="danger"
        >You cannot cancel this workout because the cancellation deadline has passed</b-alert>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Workout Info"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-form-input
              class="mb-3"
              disabled
              :value="event.name.en"
            />
            <b-form-input
              class="mb-3"
              disabled
              :value="`${formatDate(event.start_time)} - ${formatDate(event.end_time)}`"
            />
            <b-form-input
              class="mb-3"
              disabled
              :value="`${event.location.name.en} · ${event.location.short_address.en}`"
            />
          </b-form-group>
          <b-form-group
            label="Cancellation Reason"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-form-radio-group
              v-model="selectedReason"
              required
              text-field="correctedName"
              value-field="id"
              :options="englishName(cancellationReasons)"
            />
          </b-form-group>
          <b-alert show variant="info">
            <small>*Cancelling this workout will send a notification to all registered attendees.
              Once you have cancelled this workout, please notify as many attendees as you can.
            </small>
          </b-alert>
          <button
            class="btn btn-danger"
            type="submit"
            :style="!selectedReason || !event.canCancelEvent ? 'cursor:not-allowed;' : ''"
            :disabled="!selectedReason || !event.canCancelEvent"
          >Cancel Workout</button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import { makeGetRequest } from '@/util'
import { englishName } from '@/common'

export default {
  name: 'CancelWorkout',
  data () {
    return {
      event: {},
      cancellationReasons: [],
      selectedReason: null,
      loaded: false
    }
  },
  methods: {
    englishName,
    formatDate(value) {
      return moment(value).format('DD MMM HH:mm')
    },
    async onSubmit (e) {
      e.preventDefault()
      const { selectedReason } = this
      const url = `${this.$config.apiUrl}/events/${this.event.id}/cancel-event`
      try {
        await this.axios.post(url, { cancellation_reason: selectedReason })
        this.$router.replace('/admin/workouts')
      } catch (error) {
        // console.log('fail', error)
      }
    }
  },
  async created () {
    try {
      const [cancellationReasons, event] = await Promise.all([
        makeGetRequest(`${this.$config.apiUrl}/cancellation-reasons`),
        makeGetRequest(`${this.$config.apiUrl}/events/${this.$route.params.id}?with_rules=1&include=location`)
      ])
      this.cancellationReasons = cancellationReasons
      this.event = event
      this.loaded = true
    } catch (error) {
      // console.error(error)
    }
  }
}
</script>

<style>
</style>
